// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import get from "lodash.get";
import { withStyles } from "@material-ui/core/styles";
// Components
import {
    Checkbox,
    CustomTooltip,
    IconButton,
    MaterialExpansionPanel,
    TableRow
} from "../../../../../components";
import { ClassificationTag } from "../../../components";
import { ClassificationAction } from "../";
// Actions
import {
    closeViewPopup,
    openViewPopup
} from "../../../../../components/ViewPopup/ViewPopup.ducks";
import { navigateTo } from "../../../Router.ducks";
import {
    getGFIOfferEnrolments,
    saveOfferDetails
} from "../../../Offers/components/OfferDetails/OfferDetails.ducks";
// Actions
import {
    startSpinner,
    endSpinner
} from "../../../../../components/MaterialSpinner/MaterialSpinner.ducks";
// Helpers
import {
    getScrollPosition,
    openOfferDetailsView,
    parseTitle,
    renderDetailsButton
} from "../../helpers/rowHelper";
import { routesPaths } from "../../../components/Routes/Routes.helper";
import { validateLink } from "../../../helpers/links";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./ExpandableRow.css";

const ExpansionPanel = withStyles(() => ({
    detailsRoot: styles.expansionPanel.details.root,
    expanded: styles.expansionPanel.expanded,
    summaryRoot: styles.expansionPanel.summary.root,
    summaryContent: styles.expansionPanel.summary.content,
    summaryExpanded: styles.expansionPanel.summary.expanded
}))(MaterialExpansionPanel);

class ExpandableRow extends PureComponent {
    static propTypes = {
        action: PropTypes.object,
        closeViewPopup: PropTypes.func,
        dispatch: PropTypes.func,
        containerStyle: PropTypes.object.isRequired,
        disableExpandCollapse: PropTypes.bool,
        expandableActions: PropTypes.object,
        getGFIOfferEnrolments: PropTypes.func,
        isChecked: PropTypes.bool,
        isRowDefaultExpanded: PropTypes.bool,
        offer: PropTypes.object.isRequired,
        openViewPopup: PropTypes.func,
        saveOfferDetails: PropTypes.func,
        toggleBulkSelection: PropTypes.func,
        viewPopup: PropTypes.object,
        navigateTo: PropTypes.func.isRequired,
        tab: PropTypes.string.isRequired
    };

    state = {
        isRowExpandend: false,
        isSelected: false,
        selection: undefined
    };

    componentDidMount() {
        const { isRowDefaultExpanded } = this.props;

        isRowDefaultExpanded && this.handleExpandCollapseRow();
    }

    componentDidUpdate(prevProps) {
        const { disableExpandCollapse, isRowDefaultExpanded, offer } =
            this.props;

        const offerId = get(offer, "_id", undefined);
        const prevOfferId = get(prevProps, "offer._id", undefined);

        if (prevOfferId !== offerId) {
            this.setState({
                isRowExpandend: isRowDefaultExpanded || false,
                selection: undefined
            });
        }

        if (
            !get(prevProps, "disableExpandCollapse", undefined) &&
            disableExpandCollapse
        ) {
            this.handleRowSelection(false);
        }
    }

    componentWillUnmount() {
        const {
            closeViewPopup,
            viewPopup: { opened }
        } = this.props;

        opened && closeViewPopup && closeViewPopup();
    }

    handleSelection = selection => {
        this.setState({ selection });
    };

    handleRowSelection = (isSelected, cb = undefined) => {
        this.setState(
            {
                isRowExpandend: false,
                selection: undefined,
                isSelected
            },
            () => {
                cb && cb();
            }
        );
    };

    getMarginTopOnExpand = (row, height) => {
        const { isRowExpandend } = this.state;
        if (!isRowExpandend) {
            return null;
        }

        const summaryElement = document.getElementById(
            `${get(row, "url", undefined)}-summary`
        );

        let marginTopOnExpand =
            (get(summaryElement, "clientHeight", 64) - 32 - height) / 2;

        marginTopOnExpand =
            marginTopOnExpand && marginTopOnExpand > 0
                ? `${marginTopOnExpand}px`
                : undefined;
        return marginTopOnExpand;
    };

    renderRowSummary = row => {
        const { isRowExpandend, isSelected } = this.state;
        const {
            action,
            disableExpandCollapse,
            isChecked,
            toggleBulkSelection
        } = this.props;

        const offerTitle =
            get(row, "title", undefined) ||
            get(row, "display_title", undefined);

        const entity = get(row, "entity[0]", undefined);

        const externalLink = get(row, "external_link", undefined);
        const enrolmentLink = get(row, "enrolment_link", undefined);
        const classification = get(row, "classification", undefined);

        const marginTopOnExpandDetails = this.getMarginTopOnExpand(row, 24);
        const marginTopOnExpandIcon = this.getMarginTopOnExpand(row, 32);

        return (
            <Fragment>
                {!isSelected && (
                    <Checkbox
                        checked={isChecked}
                        onClick={toggleBulkSelection}
                        style={styles.checkbox}
                    />
                )}
                <div
                    dangerouslySetInnerHTML={parseTitle(offerTitle)}
                    style={{
                        ...styles.text,
                        ...styles.textOverflow,
                        ...(isRowExpandend ? styles.displayAll : {})
                    }}
                />
                {classification && (
                    <ClassificationTag
                        classification={classification}
                        style={styles.alignRight}
                    />
                )}

                {entity && this.renderEntityButton(entity)}

                {action && isSelected && <div />}
                {isSelected && <div />}
                {!externalLink && !enrolmentLink && <div />}
                {renderDetailsButton({
                    openDetailView: this.handleOpenOfferDetailsView,
                    style: {
                        container: isRowExpandend
                            ? {
                                  ...styles.rowExpanded,
                                  ...(marginTopOnExpandDetails
                                      ? {
                                            marginTop: marginTopOnExpandDetails
                                        }
                                      : {})
                              }
                            : undefined
                    }
                })}
                {(externalLink || enrolmentLink) &&
                    this.renderExternalInfoButton(
                        externalLink,
                        enrolmentLink,
                        marginTopOnExpandIcon
                    )}
                {action &&
                    !isSelected &&
                    this.renderActionButton(marginTopOnExpandIcon)}
                {!isSelected &&
                    !disableExpandCollapse &&
                    this.renderExpandCollapseButton(marginTopOnExpandIcon)}
            </Fragment>
        );
    };

    renderExpandableActions = () => {
        const { expandableActions, offer } = this.props;
        const { selection } = this.state;

        return (
            <ClassificationAction
                expandableActions={expandableActions}
                handleSelection={this.handleSelection}
                offer={offer}
                selection={selection}
            />
        );
    };

    renderExpandCollapseButton = marginTopOnExpand => {
        const { isRowExpandend } = this.state;

        return (
            <CustomTooltip
                placement="top"
                title={
                    isRowExpandend
                        ? translation.offersClassification.close
                        : translation.offersClassification.open
                }
            >
                <div
                    style={{
                        ...styles.expandCollapseButton,
                        ...(isRowExpandend ? styles.rowExpanded : {}),
                        ...(isRowExpandend ? styles.expandButton : {}),
                        ...(marginTopOnExpand
                            ? {
                                  marginTop: marginTopOnExpand
                              }
                            : {})
                    }}
                >
                    <IconButton
                        defaultClassName={"icon-arrow-down"}
                        onClick={this.handleExpandCollapseRow}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
            </CustomTooltip>
        );
    };

    renderExternalInfoButton = (
        externalLink,
        enrolmentLink,
        marginTopOnExpand
    ) => {
        const { isRowExpandend } = this.state;

        const link = externalLink
            ? validateLink(externalLink)
            : validateLink(enrolmentLink);

        return (
            <CustomTooltip
                placement="top"
                title={
                    externalLink
                        ? translation.offersClassification.externalLink
                        : translation.event.enrolmentLink
                }
            >
                <div
                    style={{
                        ...(isRowExpandend ? styles.rowExpanded : {}),
                        ...(marginTopOnExpand
                            ? {
                                  marginTop: marginTopOnExpand
                              }
                            : {})
                    }}
                >
                    <IconButton
                        defaultClassName={"icon-info-file"}
                        onClick={() => {
                            window.open(link, "_blank");
                        }}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
            </CustomTooltip>
        );
    };

    renderEntityButton = entity => {
        const { isRowExpandend } = this.state;
        return (
            <IconButton
                defaultClassName={`entity-${entity.id}`}
                onClick={() => {
                    this.handleOpenEntityEditView(entity);
                }}
                style={{
                    ...styles.button.container,
                    ...(isRowExpandend ? styles.displayAll : {})
                }}
                type={["default"]}
            >
                <span className="blur-on-click" style={styles.button.label}>
                    {entity.name}
                </span>
            </IconButton>
        );
    };

    renderActionButton = marginTopOnExpand => {
        const { isRowExpandend } = this.state;
        const { action } = this.props;

        const actionKey = get(action, "key", "");

        return (
            <CustomTooltip
                placement="top"
                title={translation.offersClassification[actionKey]}
            >
                <div
                    style={{
                        ...(isRowExpandend ? styles.rowExpanded : {}),
                        ...(marginTopOnExpand
                            ? {
                                  marginTop: marginTopOnExpand
                              }
                            : {})
                    }}
                >
                    <IconButton
                        defaultClassName={`icon-${actionKey}`}
                        onClick={() => {
                            this.handleRowSelection(true, this.handleAction);
                        }}
                        style={styles.icon}
                        type={["default"]}
                    />
                </div>
            </CustomTooltip>
        );
    };

    handleExpandCollapseRow = event => {
        const { isRowExpandend, selection } = this.state;

        event && event.stopPropagation();

        this.setState({
            isRowExpandend: !isRowExpandend,
            selection: isRowExpandend ? undefined : selection
        });
    };

    handleAction = () => {
        const { action, offer } = this.props;

        const offerURL = get(offer, "url", undefined);
        const rowElement =
            offerURL && document.getElementById(`${offerURL}-summary`);
        const rowHeight = rowElement && rowElement.clientHeight;

        const actionFunc = get(action, "action", undefined);
        actionFunc &&
            actionFunc(
                offer,
                () => {
                    this.handleRowSelection(false);
                },
                rowHeight
            );
    };

    handleOpenOfferDetailsView = () => {
        const {
            closeViewPopup,
            getGFIOfferEnrolments,
            offer,
            openViewPopup,
            saveOfferDetails
        } = this.props;
        const { isSelected } = this.state;

        const scrollPosition = getScrollPosition(
            isSelected ? "view-popup-body" : undefined
        );

        openOfferDetailsView({
            closeViewPopup,
            getGFIOfferEnrolments,
            offer,
            openViewPopup,
            saveOfferDetails,
            scrollPosition
        });
    };

    handleOpenEntityEditView = entity => {
        const { navigateTo, tab, dispatch } = this.props;
        dispatch(startSpinner());
        let path = null;

        const queryParams = new URLSearchParams({
            tab: "suppliers",
            entityId: entity?._id,
            prevTab: tab
        });

        path =
            entity?.legal_entities.length > 0
                ? routesPaths.entitiesMapping
                : routesPaths.unmappedEntities;

        path = `${path}?${queryParams.toString()}`;

        setTimeout(() => {
            dispatch(endSpinner());
            navigateTo && navigateTo(path);
        }, 1000);
    };

    render() {
        const { isRowExpandend, isSelected } = this.state;
        const { containerStyle, offer } = this.props;

        return (
            <div
                style={{
                    ...styles.container,
                    ...(isSelected ? styles.rowContainerSelected : {})
                }}
            >
                <ExpansionPanel
                    expanded={isRowExpandend}
                    id={get(offer, "url", undefined)}
                    hasExpandIcon={null}
                    summary={
                        <TableRow
                            containerStyle={{
                                ...containerStyle,
                                ...styles.rowContainer,
                                ...(isSelected
                                    ? {
                                          // To remove the checkbox
                                          gridTemplateColumns: get(
                                              containerStyle,
                                              "gridTemplateColumns",
                                              ""
                                          ).slice(4)
                                      }
                                    : {})
                            }}
                            disableOnHover={isRowExpandend || isSelected}
                            id={`${get(offer, "url", undefined)}-summary`}
                        >
                            {this.renderRowSummary(offer)}
                        </TableRow>
                    }
                    details={this.renderExpandableActions(offer)}
                />
                {isRowExpandend && <div style={styles.marker} />}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewPopup: state.viewPopup,
        tab: state.offersClassification.tab
    };
};

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators(
            {
                closeViewPopup,
                getGFIOfferEnrolments,
                openViewPopup,
                saveOfferDetails,
                navigateTo
            },
            dispatch
        )
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableRow);
